import React, { useState } from "react";
import GoogleIcon from "../../assets/img/Google Icon.png";
import FacebookIcon from "../../assets/img/Facebook Icon.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastErrorComponents } from "../../App";
import { Signup } from "../../api";

const SignUp = () => {
  const navigate = useNavigate();

  const [signupProps, setSignupProps] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleSignupChange = (event) => {
    const { name, value } = event.target;
    setSignupProps((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleErrors = () => {
    if (signupProps.name === "") {
      toast.error(`Please provide name`, ToastErrorComponents);
      return false;
    }

    if (signupProps.email === "") {
      toast.error(`Please provide email`, ToastErrorComponents);
      return false;
    }

    if (signupProps.password === "") {
      toast.error(`Please provide password`, ToastErrorComponents);
      return false;
    }

    return true;
  };

  const handleSignup = async () => {
    try {
      if (!handleErrors()) return;
      await Signup(signupProps.name, signupProps.email, signupProps.password);
      navigate("/jobDetails");
    } catch (e) {
      console.log(e);
      toast.error(`${e.message}`, ToastErrorComponents);
    }
  };

  const handleLoginWithGoogle = () => {
    window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/auth/google`;
  };

  return (
    <div className="forgot-div flex flex-col justify-center items-center w-full min-h-screen bg-[#222]">
      <div className="flex flex-row">
        <p className="text-[#fff] text-[24px] font-black">Start for free</p>
      </div>
      <div
        className="flex flex-col gap-[10px] mt-[15px]"
        onChange={handleSignupChange}
      >
        <input
          name="name"
          className="login-name-input w-[300px] h-[45px] rounded-[8px] bg-[#2B2B2B] py-[15px] pt-[12px] pl-1 text-white"
          placeholder="Name"
        />
        <input
          name="email"
          className="login-email-input w-[300px] h-[45px] rounded-[8px] bg-[#2B2B2B] py-[15px] pt-[12px] pl-1 text-white"
          placeholder="Email"
        />
        <input
          name="password"
          className="login-password-input w-[300px] h-[45px] rounded-[8px] bg-[#2B2B2B] py-[15px] pt-[12px] pl-1 text-white"
          placeholder="Password"
          type="password"
        />
        <div className="signup-forgot-passowrd-div flex justify-end mt-[5px]">
          <a className="text-[12px] text-[#5F5F5F] font-normal cursor-pointer">
            Forgot your password?
          </a>
        </div>
        <button
          className="w-[300px] h-[45px] rounded-[8px] bg-[#fff] text-[#000] text-[12px] font-normal mt-[5px]"
          onClick={handleSignup}
        >
          Sign up
        </button>
        <div className="flex flex-row mt-[10px] gap-[15px]">
          <hr className="w-[85px] h-[1px]  border-[#5F5F5F] mt-[8px]" />
          <p className="text-[12px] font-normal text-[#5F5F5F]">
            or continue with
          </p>
          <hr className="w-[85px] h-[1px] border-[#5F5F5F] mt-[8px]" />
        </div>

        <div className="flex flex-col mt-[10px] gap-[15px]">
          <button
            onClick={handleLoginWithGoogle}
            className="flex justify-center items-center gap-[8px] w-[300px] h-[45px] rounded-[8px] bg-[#222] border-[#343434] border-[1px] #343434 text-[12px] font-bold text-[#FFF]"
          >
            <img className="h-[20px] w-[20px]" src={GoogleIcon} alt="" />
            Log in
          </button>
          {/* <button className="flex justify-center items-center gap-[8px] w-[300px] h-[45px] rounded-[8px] bg-[#222] border-[#343434] border-[1px] #343434 text-[12px] font-bold text-[#FFF]">
            <img className="h-[20px] w-[20px]" src={FacebookIcon} alt="" />
            Log in
          </button> */}
        </div>
      </div>
      <p className="signup-terms-div text-[12px] font-semibold text-[#5F5F5F] mt-[10px]">
        By signing up, you agree to our <a className="underline">Terms</a>,{" "}
        <a className="underline">Data Policy</a> and{" "}
        <a className="underline">Cookies Policy</a>
      </p>
      <div className="signUp-account-div flex flex-row justify-center items-center gap-[5px] mt-[70px]">
        <p className="text-[12px] font-semibold text-[#525252]">
          Already have an account?
        </p>
        <a
          className="text-[12px] font-bold text-[#fff] cursor-pointer"
          onClick={() => {
            navigate("/login");
          }}
        >
          Log in
        </a>
      </div>
    </div>
  );
};

export default SignUp;
