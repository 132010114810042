"use client";
import React, { useRef, useState } from "react";
import TopLogo from "../../assets/img/HALI logo white 1.png";
import TopLogoText from "../../assets/img/HALI.png";
import TickBox from "../../assets/img/Tick Square.png";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastErrorComponents } from "../../App";
import { CreateUserJobDetailsWithFetch, RefreshLoginToken } from "../../api";

const BackgroundDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    industry,
    jobPosition,
    seniorityLevel,
    company,
    country,
    jobDescription,
  } = location.state?.jobDetails;
  const [isLoading, setIsLoading] = useState(false);

  const [file, setFile] = useState(null);

  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsLoading(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleCvUpload(files[0]);
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    setIsLoading(true);
    const files = e.target.files;
    if (files.length > 0) {
      handleCvUpload(files[0]);
    } else {
      setIsLoading(false);
    }
  };

  const handleCvUpload = (file) => {
    setIsLoading(true);
    setFile(file);
    localStorage.setItem("cvName", file.name);
    setIsLoading(false);
  };

  const handleErrors = () => {
    if (!file) {
      toast.error(`Please provide a CV file.`, ToastErrorComponents);
      return false;
    }

    return true;
  };

  const handleFinishButton = async () => {
    if (!handleErrors()) return;

    try {
      await RefreshLoginToken();
      await CreateUserJobDetailsWithFetch(
        industry,
        jobPosition,
        seniorityLevel,
        company,
        country,
        jobDescription,
        file
      );
      localStorage.removeItem("messages");
      localStorage.removeItem("completed");
      navigate("/dashboard");
    } catch (e) {
      console.error("Error uploading CV:", e);
      toast(e.message, ToastErrorComponents);
    }
  };

  return (
    <div className="body-div flex flex-col justify-center items-center w-full min-h-screen bg-[#1D1D1D]">
      <div className="details-head-div flex flex-row justify-center items-center gap-2 pt-4">
        <img
          className="details-top-logo h-[28px] w-[28px]"
          src={TopLogo}
          alt=""
        />
        <img
          className="details-top-logo-text h-[15px] w-[50px]"
          src={TopLogoText}
          alt=""
        />
      </div>
      <p className="hali-interview-text text-[16px] font-medium text-center text-[#D9D9D9] mt-2">
        You are one step closer to acing your next interview. Before we proceed,
        please <br /> provide me with some information on your background,
        seniority level, and areas <br /> of expertise so I can customize this
        experience to best suit your needs.
      </p>
      <div className="spans-div flex flex-row justify-center items-center mt-4">
        <div className="flex flex-col">
          <span className="step-one-span w-[330px] h-[8px]"></span>
          <p className="step-one-text text-sm font-medium text-[#585858] mt-2">
            STEP 1
          </p>
          <div className="job-details-div flex flex-row justify-between mt-2">
            <p className="job-details-text text-base font-medium text-[#fff] opacity-[0.6]">
              Job Details
            </p>
            <img className="tick-box h-[20px] w-[20px]" src={TickBox} alt="" />
          </div>
        </div>

        <div className="flex flex-col ml-6">
          <span className="step-two-span w-[330px] h-[8px]"></span>
          <p className="step-two-text text-sm font-medium text-[#585858] mt-2">
            STEP 2
          </p>
          <p className="background-details-text text-base font-medium text-[#fff] mt-2">
            Upload CV
          </p>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center mt-4 w-[530px] h-[380px] rounded-2xl bg-[#2F2F2F] border-[1px] border-solid border-[#383838]">
        <div className="input-form">
          <p className="input-header text-base font-medium text-[#fff] pl-4">
            Upload your CV
          </p>
          <hr className="border-[#383838] mt-4" />
          <div
            className="cv-container flex flex-col justify-center items-center rounded-md box-border mt-5 ml-5 w-[495px] h-[200px] border-[1px] border-dashed border-gray-400 text-[#FFF]"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <div className="rounded-lg flex flex-col items-center justify-center text-center">
              <div className="flex flex-col items-center justify-center gap-[20px]">
                <div className=" font-medium text-gray-100">Drag File Here</div>
                <div className="font-medium text-gray-100">or</div>
                <button
                  className="back-btn bg-[#585858] hover:bg-[#883ee7] hover:border-[#883ee7] w-[110px] h-[45px] rounded-md border-[1px] border-solid border-[#585858]  text-base font-semibold"
                  onClick={handleBrowseClick}
                >
                  Browse
                </button>
                <input
                  id="file"
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                  accept=".pdf, .doc, .docx" // Specify the allowed file types if needed
                  multiple={false} // Allow only one file
                />
                {isLoading && <div className="loader"></div>}
                {file && !isLoading && (
                  <div className="file-info">
                    <span>{file.name}</span>
                    <span>{(file.size / (1024 * 1024)).toFixed(2)} MB</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-btn-div flex flex-row justify-end gap-4 mt-[100px] mr-[50px]">
            <button
              className="back-btn bg-[#585858] hover:bg-[#883ee7] hover:border-[#883ee7] w-[110px] h-[45px] rounded-md border-[1px] border-solid border-[#585858] text-[#FFF] text-base font-semibold"
              onClick={() => {
                navigate("/jobDetails", {
                  state: {
                    jobDetails: {
                      industry,
                      jobPosition,
                      seniorityLevel,
                      company,
                      country,
                      jobDescription,
                    },
                  },
                });
              }}
            >
              Back
            </button>
            <button
              className="finish-btn bg-[#DF2DB8] hover:bg-[#883ee7] hover:border-[#883ee7] w-[120px] h-[45px] rounded-md border-[1px] border-solid border-[#DF2DB8] text-[#FFF] text-base font-semibold"
              onClick={handleFinishButton}
            >
              Finish
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackgroundDetails;
