import React from "react";
import ExitIcon from "../../assets/img/Exit Icon.png";

const PasswordReset = () => {
  return (
    <div className="bg-[#1D1D1D] flex flex-col justify-center items-center w-full min-h-screen">
      <div className="w-[340px] h-[415px] rounded-[16px] border-[1px] border-solid border-[#383838]">
        <div className="flex flex-row justify-between mt-[10px]">
          <p className="text-[16px] font-semibold text-[#fff] ml-[20px]">
            Password reset
          </p>
          <img
            className="forgot-exit-icon h-[16px] w-[16px] mt-[5px] mr-[30px] cursor-pointer"
            src={ExitIcon}
            alt=""
          />
        </div>
        <p className="text-[13px] font-semibold text-[#888] ml-[20px] mt-[15px]">
          Enter the email address you used to sign up to Riverside. We will send
          you a link to reset your password.
        </p>
        <input
          className="password-reset-input w-[300px] h-[45px] bg-[#2B2B2B] rounded-[8px] ml-[20px] mt-[30px]"
          placeholder="Email"
        />
        <button className="w-[300px] h-[45px] rounded-[8px] ml-[20px] bg-[#DF2DB8] mt-[20px] text-[12px] font-semibold text-[#fff]">
          Send password reset email
        </button>
        <p className="text-[18px] font-semibold text-[#FFF] ml-[20px] mt-[20px]">
          Signed up with Apple or Google or Apple?
        </p>
        <p className="text-[15px] font-bold text-[#888] ml-[20px] mt-[15px]">
          Then we can’t reset your password here. You should log in using the
          same 3rd party application you signed up with.
        </p>
      </div>
    </div>
  );
};

export default PasswordReset;
