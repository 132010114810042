import { useEffect, useRef, useState } from "react";
import dropDownIcon from "../../assets/img/dropDownIcon.png";
import dropUpIcon from "../../assets/img/dropUpIcon.png";
import { GetAllUserJobDetailsForUser } from "../../api";

const Performance = () => {
  const [selectedData, setSelectedData] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [data, setData] = useState([]);

  const categoryRefs = useRef([]);

  const getUserJobDetails = async () => {
    const data = await GetAllUserJobDetailsForUser();
    if (data) {
      console.log(data);
      setData(data);
    }
  };

  useEffect(() => {
    getUserJobDetails();
  }, []);

  const toggleDropdown = (index, offset = 0) => {
    setExpandedCategories((prev) => {
      const adjustedIndex = index + offset;
      return prev.includes(adjustedIndex)
        ? prev.filter((i) => i !== adjustedIndex)
        : [...prev, adjustedIndex];
    });
  };

  const handleViewClick = (card) => {
    setIsSelected(true);
    setSelectedData(card);
  };

  useEffect(() => {
    expandedCategories.forEach((index) => {
      if (categoryRefs.current[index]) {
        categoryRefs.current[
          index
        ].style.maxHeight = `${categoryRefs.current[index].scrollHeight}px`;
      }
    });

    categoryRefs.current.forEach((ref, index) => {
      if (!expandedCategories.includes(index) && ref) {
        ref.style.maxHeight = "65px";
      }
    });
  }, [expandedCategories]);

  const getLeftCategories = () => {
    return [
      selectedData.interviewPerformance.categoryInfo[0],
      selectedData.interviewPerformance.categoryInfo[2],
      selectedData.interviewPerformance.categoryInfo[4],
    ];
  };

  const getRightCategories = () => {
    return [
      selectedData.interviewPerformance.categoryInfo[1],
      selectedData.interviewPerformance.categoryInfo[3],
      selectedData.interviewPerformance.categoryInfo[5],
    ];
  };

  return (
    <div className="flex flex-col w-full mt-8 bg-[#232627] rounded-t-[20px]">
      {!isSelected && (
        <div>
          <p className="software-header text-xl font-medium text-[#FFF] p-4">
            Performance Review
          </p>
          <hr className="dashboard-line w-full h-[1px] border-[#343839] mt-4" />

          <div className="cards-container flex flex-wrap gap-4 p-4">
            {data?.map((card, index) => (
              <div
                key={index}
                className="card w-[300px] bg-[#252829] border-[1px] border-[#3C3D41] p-4 rounded-lg"
              >
                <p className="title text-[20px] text-white font-bold">
                  {card.jobPosition}
                </p>
                <p
                  className={`subtitle text-[16px] ${
                    card.interviewPerformance.rating >= 60
                      ? "text-[#49DD7E]"
                      : card.rating < 60 && card.rating > 20
                      ? "text-[#FFD454]"
                      : "text-[#FF7D54]"
                  } mt-2`}
                >
                  {`Overall rating: ${card.interviewPerformance.overallRatingInPercentage}/100`}
                </p>
                <p className="description text-[14px] text-white mt-2">
                  {card.interviewPerformance.summary}
                </p>
                <div className="view-button mt-4">
                  <button
                    onClick={() => handleViewClick(card)}
                    className="bg-[#585858] hover:bg-[#883ee7] hover:border-[#883ee7] w-[110px] h-[45px] rounded-md border-[1px] border-solid border-[#585858] text-[#FFF] text-base font-semibold"
                  >
                    View
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {isSelected && selectedData && (
        <div>
          <p className="software-header text-xl font-medium text-[#9747FF] p-4">
            {selectedData.jobPosition}
          </p>
          <hr className="dashboard-line w-full h-[1px] border-[#343839] mt-4" />

          <div className="cards-container flex flex-col gap-4 px-8">
            <p
              className={`subtitle text-[16px] ${
                selectedData.interviewPerformance.overallRatingInPercentage >=
                60
                  ? "text-[#49DD7E]"
                  : selectedData.rating < 60 && selectedData.rating > 20
                  ? "text-[#FFD454]"
                  : "text-[#FF7D54]"
              } mt-2`}
            >
              {`Overall rating: ${selectedData.interviewPerformance.overallRatingInPercentage}/100`}
            </p>
            <p className="description text-[14px] text-white mt-2 max-w-[600px] w-full">
              {selectedData.interviewPerformance.summary}
            </p>
          </div>

          <div className="category-container flex gap-4 p-10 px-8">
            <div className="w-full lg:w-1/2">
              {getLeftCategories().map((category, index) => (
                <div
                  key={index}
                  ref={(el) => (categoryRefs.current[index] = el)}
                  className={`category-card w-full bg-[#252829] border-[1px] border-[#3C3D41] p-4 rounded-lg transition-max-height duration-300 ease-in-out mb-4 ${
                    expandedCategories.includes(index)
                      ? "expanded"
                      : "collapsed"
                  }`}
                  style={{
                    maxHeight: expandedCategories.includes(index)
                      ? "1000px"
                      : "65px",
                  }}
                >
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() => toggleDropdown(index)}
                  >
                    <p className="category-name text-[16px] text-white">
                      {category.name}
                    </p>
                    <span className="dropdown-icon text-white">
                      {expandedCategories.includes(index) ? (
                        <img src={dropUpIcon} alt="▲" />
                      ) : (
                        <img src={dropDownIcon} alt="▼" />
                      )}
                    </span>
                  </div>
                  {expandedCategories.includes(index) && (
                    <div className="category-details mt-2 text-[14px] text-white">
                      <p className="mb-4">{`Rating: ${category.rating}`}</p>
                      <p className="mb-4">{`AI Analysis: ${category.aiAnalysis}`}</p>
                      <p>Suggestions for Improvement:</p>
                      <ul className="ml-4">
                        {category.suggestionsOfImprovement.map(
                          (suggestion, idx) => (
                            <li key={idx}>{suggestion}</li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="w-full lg:w-1/2">
              {getRightCategories().map((category, index) => (
                <div
                  key={index}
                  ref={(el) =>
                    (categoryRefs.current[index + getLeftCategories().length] =
                      el)
                  }
                  className={`category-card w-full bg-[#252829] border-[1px] border-[#3C3D41] p-4 rounded-lg transition-max-height duration-300 ease-in-out mb-4 ${
                    expandedCategories.includes(
                      index + getLeftCategories().length
                    )
                      ? "expanded"
                      : "collapsed"
                  }`}
                  style={{
                    maxHeight: expandedCategories.includes(
                      index + getLeftCategories().length
                    )
                      ? "1000px"
                      : "65px",
                  }}
                >
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() =>
                      toggleDropdown(index, getLeftCategories().length)
                    }
                  >
                    <p className="category-name text-[16px] text-white">
                      {category.name}
                    </p>
                    <span className="dropdown-icon text-white">
                      {expandedCategories.includes(
                        index + getLeftCategories().length
                      ) ? (
                        <img src={dropUpIcon} alt="▲" />
                      ) : (
                        <img src={dropDownIcon} alt="▼" />
                      )}
                    </span>
                  </div>
                  {expandedCategories.includes(
                    index + getLeftCategories().length
                  ) && (
                    <div className="category-details mt-2 text-[14px] text-white">
                      <p className="mb-4">{`Rating: ${category.rating}`}</p>
                      <p className="mb-4">{`AI Analysis: ${category.aiAnalysis}`}</p>
                      <p>Suggestions for Improvement:</p>
                      <ul className="ml-4">
                        {category.suggestionsOfImprovement.map(
                          (suggestion, idx) => (
                            <li key={idx}>{suggestion}</li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* <div className="back-button-container mt-10 px-10">
            <button
              onClick={() => setIsSelected(false)}
              className="bg-[#585858] hover:bg-[#883ee7] hover:border-[#883ee7] w-[110px] h-[45px] rounded-md border-[1px] border-solid border-[#585858] text-[#FFF] text-base font-semibold"
            >
              Back
            </button>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Performance;
