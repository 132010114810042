import React from "react";
import Confetti from "react-dom-confetti";

const ConfettiComponent = ({ trigger }) => {
  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    decay: 0.95,
  };

  return <Confetti active={trigger} config={config} />;
};

export default ConfettiComponent;
