"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
exports.__esModule = true;
exports.everything = exports.GenqlError = exports.createFetcher = exports.linkTypeMap = exports.generateGraphqlOperation = exports.createClient = void 0;
// @ts-nocheck
var createClient_1 = require("./createClient");
__createBinding(exports, createClient_1, "createClient");
var generateGraphqlOperation_1 = require("./generateGraphqlOperation");
__createBinding(exports, generateGraphqlOperation_1, "generateGraphqlOperation");
var linkTypeMap_1 = require("./linkTypeMap");
__createBinding(exports, linkTypeMap_1, "linkTypeMap");
// export { Observable } from 'zen-observable-ts'
var fetcher_1 = require("./fetcher");
__createBinding(exports, fetcher_1, "createFetcher");
var error_1 = require("./error");
__createBinding(exports, error_1, "GenqlError");
exports.everything = {
    __scalar: true
};
