const SettingsComponent = () => {
  return (
    <div className="dashboard-div flex flex-col w-[1010px] mt-8 bg-[#232627] rounded-t-[20px]">
      <p className="software-header text-xl font-medium text-[#FFF] p-4">
        Settings
      </p>
      <hr className="dashboard-line w-full h-[1px] border-[#343839] mt-4" />
    </div>
  );
};

export default SettingsComponent;
