"use strict";
// @ts-nocheck
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
exports.__esModule = true;
exports.enumUserRole = exports.enumOpenAiSpeechVoices = exports.isLoggedInUser = exports.isMutation = exports.isCategoryInfo = exports.isInterviewPerformance = exports.isUser = exports.isUserJobDetails = exports.isInterview = exports.isAIResponseType = exports.isQuery = void 0;
var Query_possibleTypes = ["Query"];
var isQuery = function (obj) {
    if (!(obj === null || obj === void 0 ? void 0 : obj.__typename))
        throw new Error('__typename is missing in "isQuery"');
    return Query_possibleTypes.includes(obj.__typename);
};
exports.isQuery = isQuery;
var AIResponseType_possibleTypes = ["AIResponseType"];
var isAIResponseType = function (obj) {
    if (!(obj === null || obj === void 0 ? void 0 : obj.__typename))
        throw new Error('__typename is missing in "isAIResponseType"');
    return AIResponseType_possibleTypes.includes(obj.__typename);
};
exports.isAIResponseType = isAIResponseType;
var Interview_possibleTypes = ["Interview"];
var isInterview = function (obj) {
    if (!(obj === null || obj === void 0 ? void 0 : obj.__typename))
        throw new Error('__typename is missing in "isInterview"');
    return Interview_possibleTypes.includes(obj.__typename);
};
exports.isInterview = isInterview;
var UserJobDetails_possibleTypes = ["UserJobDetails"];
var isUserJobDetails = function (obj) {
    if (!(obj === null || obj === void 0 ? void 0 : obj.__typename))
        throw new Error('__typename is missing in "isUserJobDetails"');
    return UserJobDetails_possibleTypes.includes(obj.__typename);
};
exports.isUserJobDetails = isUserJobDetails;
var User_possibleTypes = ["User"];
var isUser = function (obj) {
    if (!(obj === null || obj === void 0 ? void 0 : obj.__typename))
        throw new Error('__typename is missing in "isUser"');
    return User_possibleTypes.includes(obj.__typename);
};
exports.isUser = isUser;
var InterviewPerformance_possibleTypes = ["InterviewPerformance"];
var isInterviewPerformance = function (obj) {
    if (!(obj === null || obj === void 0 ? void 0 : obj.__typename))
        throw new Error('__typename is missing in "isInterviewPerformance"');
    return InterviewPerformance_possibleTypes.includes(obj.__typename);
};
exports.isInterviewPerformance = isInterviewPerformance;
var CategoryInfo_possibleTypes = ["CategoryInfo"];
var isCategoryInfo = function (obj) {
    if (!(obj === null || obj === void 0 ? void 0 : obj.__typename))
        throw new Error('__typename is missing in "isCategoryInfo"');
    return CategoryInfo_possibleTypes.includes(obj.__typename);
};
exports.isCategoryInfo = isCategoryInfo;
var Mutation_possibleTypes = ["Mutation"];
var isMutation = function (obj) {
    if (!(obj === null || obj === void 0 ? void 0 : obj.__typename))
        throw new Error('__typename is missing in "isMutation"');
    return Mutation_possibleTypes.includes(obj.__typename);
};
exports.isMutation = isMutation;
var LoggedInUser_possibleTypes = ["LoggedInUser"];
var isLoggedInUser = function (obj) {
    if (!(obj === null || obj === void 0 ? void 0 : obj.__typename))
        throw new Error('__typename is missing in "isLoggedInUser"');
    return LoggedInUser_possibleTypes.includes(obj.__typename);
};
exports.isLoggedInUser = isLoggedInUser;
exports.enumOpenAiSpeechVoices = {
    ALLOY: "ALLOY",
    ECHO: "ECHO",
    FABLE: "FABLE",
    ONYX: "ONYX",
    NOVA: "NOVA",
    SHIMMER: "SHIMMER"
};
exports.enumUserRole = {
    Admin: "Admin",
    Owner: "Owner",
    User: "User"
};
