import React, { useState } from "react";
import TopLogoText from "../../assets/img/HALI.png";
import GoogleIcon from "../../assets/img/Google Icon.png";
import { useNavigate } from "react-router-dom";
import { ToastErrorComponents } from "../../App";
import { toast } from "react-toastify";
import {
  GetLatestUserJobDetails,
  GetUserLatestInterviews,
  Login as LoginApi,
} from "../../api";
import { userJobDescription } from "../../utils/jobDescription";

const Login = () => {
  const navigate = useNavigate();

  const [signinProps, setSigninProps] = useState({
    email: "",
    password: "",
  });

  const handleSigninChange = (event) => {
    const { name, value } = event.target;
    setSigninProps((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleErrors = () => {
    if (signinProps.email === "") {
      toast.error(`Please provide email`, ToastErrorComponents);
      return false;
    }

    if (signinProps.password === "") {
      toast.error(`Please provide password`, ToastErrorComponents);
      return false;
    }

    return true;
  };

  const handleSignin = async () => {
    try {
      if (!handleErrors()) return;
      await LoginApi(signinProps.email, signinProps.password);
      const latestInterviews = await GetUserLatestInterviews();
      const latestUserJobDetails = await GetLatestUserJobDetails();
      if (latestInterviews.length) {
        userJobDescription.set(JSON.stringify(latestUserJobDetails));
        navigate("/dashboard");
      } else {
        if (latestUserJobDetails) {
          userJobDescription.set(JSON.stringify(latestUserJobDetails));
          navigate("/dashboard");
        } else {
          navigate("/jobDetails");
        }
      }
    } catch (e) {
      console.log(e);
      toast.error(`${e.message}`, ToastErrorComponents);
    }
  };

  const handleLoginWithGoogle = () => {
    window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/auth/google`;
  };

  return (
    <div className="forgot-div flex flex-col justify-center items-center w-full min-h-screen bg-[#222]">
      <div className="flex flex-row">
        <p className="text-[#fff] text-[24px] font-black">Log in to</p>
        <img
          className="TopLogoText h-[15px] w-[50px] mt-[10px] ml-[5px]"
          src={TopLogoText}
          alt=""
        />
      </div>
      <div
        className="flex flex-col gap-[10px] mt-[15px]"
        onChange={handleSigninChange}
      >
        <input
          name="email"
          className="login-email-input w-[300px] h-[45px] rounded-[8px] bg-[#2B2B2B] py-[15px] pt-[12px] pl-1 text-white"
          placeholder="Email"
        />
        <input
          name="password"
          className="login-password-input w-[300px] h-[45px] rounded-[8px] bg-[#2B2B2B] py-[15px] pt-[12px] pl-1 text-white"
          placeholder="Password"
          type="password"
        />
        <div className="flex justify-end mt-[5px]">
          <div className="text-[12px] text-[#5F5F5F] font-normal cursor-pointer">
            Forgot your password?
          </div>
        </div>
        <button
          className="w-[300px] h-[45px] rounded-[8px] bg-[#fff] text-[#000] text-[12px] font-normal mt-[5px]"
          onClick={handleSignin}
        >
          Log in
        </button>
        <div className="flex flex-row mt-[10px] gap-[15px]">
          <hr className="w-[85px] h-[1px]  border-[#5F5F5F] mt-[8px]" />
          <p className="text-[12px] font-normal text-[#5F5F5F]">
            or continue with
          </p>
          <hr className="w-[85px] h-[1px] border-[#5F5F5F] mt-[8px]" />
        </div>

        <div className="flex flex-col mt-[10px] gap-[15px]">
          <button
            onClick={handleLoginWithGoogle}
            className="flex justify-center items-center gap-[8px] w-[300px] h-[45px] rounded-[8px] bg-[#222] border-[#343434] border-[1px] #343434 text-[12px] font-bold text-[#FFF]"
          >
            <img className="h-[20px] w-[20px]" src={GoogleIcon} alt="" />
            Log in
          </button>
          {/* <button className="flex justify-center items-center gap-[8px] w-[300px] h-[45px] rounded-[8px] bg-[#222] border-[#343434] border-[1px] #343434 text-[12px] font-bold text-[#FFF]">
            <img className="h-[20px] w-[20px]" src={FacebookIcon} alt="" />
            Log in
          </button> */}
        </div>
        <div className="login-account-div flex flex-row justify-center items-center mt-[100px] gap-[5px]">
          <p className="text-[12px] font-semibold text-[#525252]">
            Don't have an account?
          </p>
          <div
            className="text-[12px] font-bold text-[#fff] cursor-pointer"
            onClick={() => {
              navigate("/signup");
            }}
          >
            Sign up
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
