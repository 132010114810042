import { token } from "../utils/token";
// import "dotenv/config";

import { createClient } from "../graphql/client";

export const client = createClient({
  url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  batch: true,
  headers: async () => ({
    authorization: await token.asHeader(),
    "ngrok-skip-browser-warning": "*",
    "Bypass-Tunnel-Reminder": "*",
  }),
});
