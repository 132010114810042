import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "./pages/homePage/homePage";
import JobDetails from "./pages/background/jobDetails";
import BackgroundDetails from "./pages/background/backgroundDetails";
import Dashboard from "./pages/Dashboard/dashboard";
import StartInterview from "./pages/StartInterview/startInterview";
import PasswordReset from "./pages/PasswordReset/passwordReset";
import Login from "./pages/Login/login";
import SignUp from "./pages/SignUp/signup";

export const ToastErrorComponents = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

export const ToastSuccessComponents = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/jobDetails" element={<JobDetails />} />
          <Route path="/backgroundDetails" element={<BackgroundDetails />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/startInterview" element={<StartInterview />} />
          <Route path="/passwordReset" element={<PasswordReset />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
