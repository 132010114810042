"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
exports.__esModule = true;
exports.generateMutationOp = exports.generateQueryOp = exports.everything = exports.createClient = exports.GenqlError = void 0;
var runtime_1 = require("./runtime");
exports.GenqlError = runtime_1.GenqlError;
var types_1 = require("./types");
__exportStar(require("./schema"), exports);
var typeMap = (0, runtime_1.linkTypeMap)(types_1["default"]);
var createClient = function (options) {
    return (0, runtime_1.createClient)(__assign(__assign({ url: "http://localhost:3333/graphql" }, options), { queryRoot: typeMap.Query, mutationRoot: typeMap.Mutation, subscriptionRoot: typeMap.Subscription }));
};
exports.createClient = createClient;
exports.everything = {
    __scalar: true
};
var generateQueryOp = function (fields) {
    return (0, runtime_1.generateGraphqlOperation)("query", typeMap.Query, fields);
};
exports.generateQueryOp = generateQueryOp;
var generateMutationOp = function (fields) {
    return (0, runtime_1.generateGraphqlOperation)("mutation", typeMap.Mutation, fields);
};
exports.generateMutationOp = generateMutationOp;
