"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.GenqlError = void 0;
// @ts-nocheck
var GenqlError = /** @class */ (function (_super) {
    __extends(GenqlError, _super);
    function GenqlError(errors, data) {
        var _this = this;
        var message = Array.isArray(errors)
            ? errors.map(function (x) { return (x === null || x === void 0 ? void 0 : x.message) || ""; }).join("\n")
            : "";
        if (!message) {
            message = "GraphQL error";
        }
        _this = _super.call(this, message) || this;
        _this.errors = [];
        _this.errors = errors;
        _this.data = data;
        return _this;
    }
    return GenqlError;
}(Error));
exports.GenqlError = GenqlError;
