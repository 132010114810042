import React, { useEffect, useRef, useState } from "react";
import ExitIcon from "../../assets/img/Exit Icon.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastErrorComponents } from "../../App";
import {
  CreateUserJobDetails,
  CreateUserJobDetailsWithFetch,
  RefreshLoginToken,
} from "../../api";
import { CheckCircle, Circle } from "@mui/icons-material";
import { authUser } from "../../utils/user";

const StartInterview = () => {
  const navigate = useNavigate();
  const [userCv, setUserCv] = useState();
  const jobIndustries = [
    "Accounting",
    "Aerospace",
    "Agriculture",
    "Automotive",
    "Biotechnology",
    "Construction",
    "Education",
    "Energy",
    "Finance",
    "Healthcare",
    "Information Technology",
    "Manufacturing",
    "Media",
    "Real Estate",
    "Retail",
    "Telecommunications",
    "Transportation",
  ];

  const seniorityLevels = [
    "Intern",
    "Junior",
    "Mid-Level",
    "Senior",
    "Lead",
    "Manager",
    "Director",
    "Vice President",
    "Senior Vice President",
    "Executive",
  ];

  const [jobDescription, setJobDescription] = useState({
    industry: "",
    jobPosition: "",
    seniorityLevel: "",
    company: "",
    country: "",
    jobDescription: "",
  });

  const [industryValue, setIndustryValue] = useState(
    !jobIndustries.includes(jobDescription.industry)
      ? jobDescription.industry
      : ""
  );
  const [seniorityValue, setSeniorityValue] = useState(
    !seniorityLevels.includes(jobDescription.seniorityLevel)
      ? jobDescription.seniorityLevel
      : ""
  );

  const [disableIndustryInput, setDisableIndustryInput] = useState(false);
  const [disableSeniorityLevelInput, setDisableSeniorityLevelInput] =
    useState(false);

  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef(null);

  const industryInputRef = useRef(null);
  const seniorityInputRef = useRef(null);
  useEffect(() => {
    if (!disableSeniorityLevelInput) {
      seniorityInputRef.current.focus();
    }
  }, [disableSeniorityLevelInput]);
  useEffect(() => {
    if (!disableIndustryInput) {
      industryInputRef.current.focus();
    }
  }, [disableIndustryInput]);

  useEffect(() => {
    if (!userCv) {
      getUser();
    }
  }, [userCv]);

  const getUser = async () => {
    const userString = await authUser.get();
    const user = JSON.parse(userString);
    if (user) {
      setUserCv(user.cvName);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsLoading(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleCvUpload(files[0]);
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    setIsLoading(true);
    const files = e.target.files;
    if (files.length > 0) {
      handleCvUpload(files[0]);
    } else {
      setIsLoading(false);
    }
  };

  const handleCvUpload = (file) => {
    setIsLoading(true);
    setFile(file);
    setUserCv(file.name);
    localStorage.setItem("cvName", file.name);
    setIsLoading(false);
  };

  const handleJobDescriptionChange = (event) => {
    const { name, value } = event.target;
    setJobDescription((prevData) => ({ ...prevData, [name]: value }));
    if (name === "industry") {
      if (!jobIndustries.includes(value)) {
        if (value === "Other") {
          setIndustryValue("");
        } else {
          setIndustryValue(value);
        }
        setDisableIndustryInput(false);
      } else {
        setIndustryValue("");
        setDisableIndustryInput(true);
      }
    }
    if (name === "seniorityLevel") {
      if (!seniorityLevels.includes(value)) {
        if (value === "Other") {
          setSeniorityValue("");
        } else {
          setSeniorityValue(value);
        }
        setDisableSeniorityLevelInput(false);
      } else {
        setSeniorityValue("");
        setDisableSeniorityLevelInput(true);
      }
    }
  };

  const handleErrors = () => {
    if (jobDescription.industry === "") {
      toast.error(`Please provide industry`, ToastErrorComponents);
      return false;
    }

    if (jobDescription.jobPosition === "") {
      toast.error(`Please provide job position`, ToastErrorComponents);
      return false;
    }

    if (jobDescription.seniorityLevel === "") {
      toast.error(`Please provide seniority level`, ToastErrorComponents);
      return false;
    }

    if (jobDescription.company === "") {
      toast.error(`Please provide company`, ToastErrorComponents);
      return false;
    }

    if (jobDescription.country === "") {
      toast.error(`Please provide country`, ToastErrorComponents);
      return false;
    }

    if (jobDescription.jobDescription === "") {
      toast.error(`Please provide job description`, ToastErrorComponents);
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!handleErrors()) return;
    try {
      await RefreshLoginToken();
      if (file) {
        await CreateUserJobDetailsWithFetch(
          jobDescription.industry,
          jobDescription.jobPosition,
          jobDescription.seniorityLevel,
          jobDescription.company,
          jobDescription.country,
          jobDescription.jobDescription,
          file
        );
      } else {
        await CreateUserJobDetails(
          jobDescription.industry,
          jobDescription.jobPosition,
          jobDescription.seniorityLevel,
          jobDescription.company,
          jobDescription.country,
          jobDescription.jobDescription
        );
      }

      localStorage.removeItem("messages");
      localStorage.removeItem("completed");
      navigate("/dashboard");
    } catch (e) {
      console.log(e);
      toast.error(`${e.message}`, ToastErrorComponents);
    }
  };

  return (
    <div className="forgot-div flex justify-center items-center w-full min-h-screen bg-[#222]">
      <div className="forgot-dashboard flex flex-col w-[740px] h-[580px] rounded-[16px] bg-[#222] border-[1px] border-solid border-[#383838] mt-[20px] mb-[20px]">
        <div className="forgot-header flex flex-row justify-between mt-[15px]">
          <p className="forgot-header-text text-base font-medium text-white ml-[20px]">
            You can edit information before proceeding{" "}
          </p>
          <img
            className="forgot-exit-icon h-[16px] w-[16px] mt-[5px] mr-[30px] cursor-pointer"
            onClick={() => {
              navigate("/dashboard");
            }}
            src={ExitIcon}
            alt=""
          />
        </div>
        <hr className="dashboard-line w-full h-[1px] mt-[10px] border-[#343839]" />
        <div className="forgot-section flex flex-col ml-[20px] mt-[10px]">
          <div>
            <label className="forgot-industry-label text-[12px] font-semibold text-[#D9D9D9]">
              Industry
            </label>
            <div className="flex flex-row justify-between mt-[10px]">
              <select
                className="forgotSelect w-[320px] h-[40px] rounded-[8px] bg-[#2B2B2B]"
                name="industry"
                value={jobDescription.industry}
                onChange={handleJobDescriptionChange}
              >
                <option className="text-[10px] font-semibold text-[#fff]">
                  Other
                </option>
                {jobIndustries.map((industry, index) => (
                  <option
                    key={index}
                    className="text-[10px] font-semibold text-[#fff]"
                  >
                    {industry}
                  </option>
                ))}
              </select>
              <input
                ref={industryInputRef}
                name="industry"
                placeholder="Type other here "
                className="forgot-industry-input forgot-password-border w-[320px] h-[40px] rounded-[8px] bg-[#141718] mr-[40px] pl-1 text-white"
                onChange={handleJobDescriptionChange}
                value={industryValue}
                disabled={disableIndustryInput}
              />
            </div>
          </div>
          <label className="forgot-label text-[12px] font-semibold text-[#D9D9D9] mt-[20px]">
            Job position
          </label>
          <input
            name="jobPosition"
            placeholder="eg. Product designer  "
            className="forgot-position-input forgot-password-border w-[680px] h-[40px] rounded-[8px] bg-[#141718] mt-[10px] pl-1 text-white"
            onChange={handleJobDescriptionChange}
            value={jobDescription.jobPosition}
          />
          <label className="forgot-label text-[12px] font-semibold text-[#D9D9D9] mt-[20px]">
            Seniority Level
          </label>
          <div className="flex flex-row justify-between mt-[10px]">
            <select
              name="seniorityLevel"
              className="forgotSelect mySelect w-[320px] h-[40px] rounded-[8px] bg-[#2B2B2B]"
              value={jobDescription.seniorityLevel}
              onChange={handleJobDescriptionChange}
            >
              <option className="text-[10px] font-semibold text-[#fff]">
                Other
              </option>
              {seniorityLevels.map((seniorityLevel, index) => (
                <option
                  key={index}
                  className="text-[10px] font-semibold text-[#fff]"
                >
                  {seniorityLevel}
                </option>
              ))}
            </select>
            <input
              ref={seniorityInputRef}
              name="seniorityLevel"
              placeholder="Type other here"
              className="forgot-seniority-input forgot-password-border w-[320px] h-[40px] rounded-[8px] bg-[#141718] mr-[40px] pl-1 text-white"
              onChange={handleJobDescriptionChange}
              value={seniorityValue}
              disabled={disableSeniorityLevelInput}
            />
          </div>

          <div className="company-country-label-gap-start-interview flex flex-row mt-4">
            <label className="forgot-label text-[12px] font-semibold text-[#D9D9D9] mt-[20px]">
              Company
            </label>
            <label className="forgot-label text-[12px] font-semibold text-[#D9D9D9] mt-[20px]">
              Country
            </label>
          </div>

          <div className="industry-details-div-start-interview flex flex-row mt-[5px]">
            <input
              className="forgot-seniority-input forgot-password-border w-[320px] h-[40px] rounded-[8px] bg-[#141718] pl-1 text-white"
              placeholder="Type company here"
              name="company"
              value={jobDescription.company}
              onChange={handleJobDescriptionChange}
            />

            <input
              className="forgot-seniority-input forgot-password-border w-[320px] h-[40px] rounded-[8px] bg-[#141718] pl-1 text-white"
              placeholder="Type country here"
              name="country"
              value={jobDescription.country}
              onChange={handleJobDescriptionChange}
            />
          </div>

          <div className="flex flex-col mt-4">
            <label className="text-[#D9D9D9] font-semibold text-xs">
              Job Description
            </label>
            <textarea
              className="job-input-start-interview w-[500px] h-[174px] bg-[#141718] mt-[5px] pl-1 text-white"
              placeholder="Type Job Description Here"
              name="jobDescription"
              value={jobDescription.jobDescription}
              onChange={handleJobDescriptionChange}
            />
          </div>

          <div className="flex flex-row mt-4">
            <label className="forgot-label text-[12px] font-semibold text-[#D9D9D9] mt-[20px]">
              CV/Resume
            </label>
          </div>

          <div className="resume-name w-[685px] h-[40px] mt-[10px] ml-[10px] bg-[#2B2B2B]">
            <div className="w-auto justify-between flex items-center mt-[5px] ">
              <label
                htmlFor="cvCheckBox"
                className="text-sm w-auto flex-shrink-0 overflow-hidden font-semibold text-[#D9D9D9] ml-[10px]"
              >
                {userCv ? userCv : "Resume name"}
              </label>
              <div className="mr-[10px] cursor-pointer">
                <CheckCircle
                  style={{
                    color: "#1df42f",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row mt-4">
            <label className="forgot-label text-[12px] font-semibold text-[#D9D9D9] mt-[20px]">
              Upload New CV/Resume
            </label>
          </div>

          <div className="flex flex-col ml-[10px] mt-[10px] mb-[20px] gap-[20px]">
            <button
              className="back-btn bg-[#585858] hover:bg-[#883ee7] hover:border-[#883ee7] w-[110px] h-[45px] rounded-md border-[1px] border-solid border-[#585858] text-base font-semibold text-white"
              onClick={handleBrowseClick}
            >
              Browse
            </button>
            <input
              id="file"
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileInputChange}
              accept=".pdf, .doc, .docx" // Specify the allowed file types if needed
              multiple={false} // Allow only one file
            />
            {isLoading && <div className="loader"></div>}
          </div>
        </div>
        <div className="flex flex-row justify-center items-center mb-[20px]">
          <button
            className="forgot-interview-btn mt-[30px] w-[300px] h-[40px] bg-[#DF2DB8] rounded-[8px] text-[#fff] text-[12px] ont-semibold"
            onClick={handleSubmit}
          >
            Start Interview
          </button>
        </div>
      </div>
    </div>
  );
};

export default StartInterview;
