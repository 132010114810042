"use client";
import { client } from "../config/client";
import { token } from "../utils/token";
import { authUser } from "../utils/user";
import { userJobDescription } from "../utils/jobDescription";
import OpenAI from "openai";
import { enumOpenAiSpeechVoices } from "../graphql/client";

export const RefreshLoginToken = async () => {
  try {
    const { refreshLogInToken: data } = await client.mutation({
      refreshLogInToken: {},
    });
    token.set(data);
  } catch (e) {
    throw new Error(e);
  }
};

export const Login = async (email, password) => {
  try {
    const { logIn: data } = await client.mutation({
      logIn: {
        __args: { input: { email, password } },
        sessionToken: true,
        user: {
          _id: true,
          name: true,
          email: true,
          role: true,
          emailVerified: true,
          cvName: true,
          cvDataExtraction: true,
          avatar: true,
        },
      },
    });
    console.log(data);
    token.set(data.sessionToken);
    authUser.set(JSON.stringify(data.user));
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export const Signup = async (name, email, password) => {
  try {
    await client.mutation({
      userSignup: {
        __args: {
          input: {
            name,
            email,
            password,
          },
        },
        _id: true,
        name: true,
        email: true,
        emailVerified: true,
        avatar: true,
      },
    });

    await Login(email, password);
  } catch (e) {
    console.log("Error at signup: ", e.message);
    throw new Error(e);
  }
};

export const GetUser = async () => {
  try {
    const { getLoggedInUser: data } = await client.query({
      getLoggedInUser: {
        _id: true,
        name: true,
        email: true,
        role: true,
        emailVerified: true,
        cvName: true,
        cvDataExtraction: true,
        avatar: true,
      },
    });
    authUser.set(JSON.stringify(data));
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export const CreateUserJobDetails = async (
  industry,
  jobPosition,
  seniorityLevel,
  company,
  country,
  jobDescription
) => {
  try {
    const { createUserJobDetails: data } = await client.mutation({
      createUserJobDetails: {
        __args: {
          input: {
            industry,
            jobPosition,
            seniorityLevel,
            company,
            country,
            jobDescription,
          },
        },
        _id: true,
        jobPosition: true,
        industry: true,
        seniorityLevel: true,
        company: true,
        country: true,
        jobDescription: true,
        background: true,
        areaOfExpertiseOrSkills: true,
        lastAiQuestion: true,
        questionNumber: true,
      },
    });

    userJobDescription.set(JSON.stringify(data));
    return data;
  } catch (e) {
    console.log("Error at creating job description: ", e.message);
    throw new Error(e);
  }
};

export const CreateUserJobDetailsWithFetch = async (
  industry,
  jobPosition,
  seniorityLevel,
  company,
  country,
  jobDescription,
  file
) => {
  try {
    const tok = await token.get();
    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({
        query: `
        mutation ($file: Upload!, $industry: String!, $jobPosition: String!, $seniorityLevel: String!, $company: String!, $country: String!, $jobDescription: String!) {
          createUserJobDetails(file: $file, input: {
            industry: $industry,
            jobPosition: $jobPosition,
            seniorityLevel: $seniorityLevel,
            company: $company,
            country: $country,
            jobDescription: $jobDescription
          }) {
            _id
            jobPosition
            industry
            seniorityLevel
            company
            country
            jobDescription
          }
        }
      `,
        variables: {
          file: null, // placeholder, will be set below
          industry,
          jobPosition,
          seniorityLevel,
          company,
          country,
          jobDescription,
        },
      })
    );

    formData.append(
      "map",
      JSON.stringify({
        0: ["variables.file"],
      })
    );

    formData.append("0", file);

    const response = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
      method: "POST",
      body: formData,
      headers: { Authorization: `Bearer ${tok}` },
    });

    const result = await response.json();

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    userJobDescription.set(JSON.stringify(result.data.createUserJobDetails));
    return result.data.createUserJobDetails;
  } catch (e) {
    console.log("Error at creating job description: ", e.message);
    throw new Error(e);
  }
};

export const GetUserJobDetails = async (userJobDetailsId) => {
  try {
    const { getUserJobDetails: data } = await client.query({
      getUserJobDetails: {
        __args: {
          userJobDetailsId,
        },
        _id: true,
        jobPosition: true,
        industry: true,
        seniorityLevel: true,
        company: true,
        country: true,
        jobDescription: true,
        lastAiQuestion: true,
        questionNumber: true,
        cvDataExtraction: true,
      },
    });

    userJobDescription.set(JSON.stringify(data));
    return data;
  } catch (e) {
    console.log(e.message);
    throw new Error(e);
  }
};

export const UpdateUserJobDetails = async (
  userJobDetailsId,
  lastAiQuestion,
  questionNumber
) => {
  try {
    const { updateUserJobDetails: data } = await client.mutation({
      updateUserJobDetails: {
        __args: {
          input: {
            userJobDetailsId,
            lastAiQuestion,
            questionNumber,
          },
        },
        _id: true,
        jobPosition: true,
        industry: true,
        seniorityLevel: true,
        company: true,
        country: true,
        jobDescription: true,
        background: true,
        areaOfExpertiseOrSkills: true,
        lastAiQuestion: true,
        questionNumber: true,
      },
    });

    return data;
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export const GetJobDetailInterviewQuestionsAndAnswers = async (
  jobDetailsId,
  pageNumber = 1,
  pageSize = 20
) => {
  try {
    const { getJobDetailInterview: data } = await client.query({
      getJobDetailInterview: {
        __args: {
          jobDetailsId,
          pagination: { pageNumber, pageSize },
        },
        _id: true,
        aiQuestion: true,
        userResponse: true,
      },
    });

    return data;
  } catch (e) {
    console.log(e.message);
    throw new Error(e);
  }
};

export const GetLatestUserJobDetails = async () => {
  try {
    const { getLatestUserJobDetails: data } = await client.query({
      getLatestUserJobDetails: {
        _id: true,
        jobPosition: true,
        industry: true,
        seniorityLevel: true,
        company: true,
        country: true,
        jobDescription: true,
        lastAiQuestion: true,
        questionNumber: true,
        cvDataExtraction: true,
      },
    });
    if (data) {
      userJobDescription.set(data);
      return data;
    }
  } catch (e) {
    throw new Error(e);
  }
};

export const GetAllUserJobDetailsForUser = async () => {
  try {
    const { getAllUserJobDetails: data } = await client.query({
      getAllUserJobDetails: {
        _id: true,
        jobPosition: true,
        industry: true,
        seniorityLevel: true,
        company: true,
        country: true,
        jobDescription: true,
        lastAiQuestion: true,
        questionNumber: true,
        cvDataExtraction: true,
        interviewPerformance: {
          overallRatingInPercentage: true,
          summary: true,
          categoryInfo: {
            name: true,
            rating: true,
            aiAnalysis: true,
            suggestionsOfImprovement: true,
          },
        },
      },
    });
    if (data) {
      return data;
    }
  } catch (e) {
    throw new Error(e);
  }
};

export const GetUserLatestInterviews = async () => {
  try {
    const { getUserLatestInterviews: data } = await client.query({
      getUserLatestInterviews: {
        _id: true,
        aiQuestion: true,
        userResponse: true,
        userJobDetails: {
          _id: true,
          jobPosition: true,
          industry: true,
          seniorityLevel: true,
          company: true,
          country: true,
          jobDescription: true,
          lastAiQuestion: true,
          questionNumber: true,
          cvDataExtraction: true,
        },
      },
    });

    if (data.length) {
      const existingMessages = [];
      for (const interview of data) {
        existingMessages.push({ text: interview.aiQuestion, user: "ai" });
        existingMessages.push({
          text: interview.userResponse,
          user: "user",
        });
      }
      if (existingMessages.length >= 11) {
        existingMessages.push({
          text: `Congratulations, you have completed your interview questions.`,
          user: "ai",
        });
      }
      localStorage.setItem("messages", JSON.stringify(existingMessages));
      userJobDescription.set(JSON.stringify(data[0].userJobDetails));
    }
    return data;
  } catch (e) {
    console.log(e.message);
    throw new Error(e);
  }
};

export const SaveNewInterviewResponses = async (
  userJobDetailsId,
  aiQuestion,
  userResponse
) => {
  try {
    const { createInterview: data } = await client.mutation({
      createInterview: {
        __args: {
          input: {
            aiQuestion,
            userResponse,
            userJobDetailsId,
          },
        },
        _id: true,
        aiQuestion: true,
        userResponse: true,
      },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const GetOpenAiTextResponse = async (prompt) => {
  try {
    const { getAiCompletionText: data } = await client.query({
      getAiCompletionText: {
        __args: { prompt },
      },
    });
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const GetOpenAiAudioResponse = async (audioFile) => {
  try {
    const tok = await token.get();
    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({
        query: `
        query ($file: Upload!) {
          getTextFromAudio(file: $file)}
      `,
        variables: {
          file: null,
        },
      })
    );

    formData.append(
      "map",
      JSON.stringify({
        0: ["variables.file"],
      })
    );

    formData.append("0", audioFile);

    const response = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
      method: "POST",
      body: formData,
      headers: { Authorization: `Bearer ${tok}` },
    });

    const result = await response.json();

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    return result.data.getTextFromAudio.replace(/\^s/gi, "");
  } catch (e) {
    console.log(e.message);
  }
};

export const GetOpenAiSpeechResponse = async (text) => {
  try {
    const { getAudioFromText: data } = await client.query({
      getAudioFromText: {
        __args: {
          input: { prompt: text, voice: enumOpenAiSpeechVoices.ALLOY },
        },
        audioBlob: true,
      },
    });
    return data.audioBlob;
  } catch (e) {
    console.log(e);
    console.log(e.message);
  }
};
