"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.getFieldFromPath = exports.generateGraphqlOperation = void 0;
var parseRequest = function (request, ctx, path) {
    if (typeof request === "object" && "__args" in request) {
        var args_1 = request.__args;
        var fields = __assign({}, request);
        delete fields.__args;
        var argNames = Object.keys(args_1);
        if (argNames.length === 0) {
            return parseRequest(fields, ctx, path);
        }
        var field_1 = (0, exports.getFieldFromPath)(ctx.root, path);
        var argStrings = argNames.map(function (argName) {
            ctx.varCounter++;
            var varName = "v".concat(ctx.varCounter);
            var typing = field_1.args && field_1.args[argName]; // typeMap used here, .args
            if (!typing) {
                throw new Error("no typing defined for argument `".concat(argName, "` in path `").concat(path.join("."), "`"));
            }
            ctx.variables[varName] = {
                value: args_1[argName],
                typing: typing
            };
            return "".concat(argName, ":$").concat(varName);
        });
        return "(".concat(argStrings, ")").concat(parseRequest(fields, ctx, path));
    }
    else if (typeof request === "object" && Object.keys(request).length > 0) {
        var fields_1 = request;
        var fieldNames = Object.keys(fields_1).filter(function (k) { return Boolean(fields_1[k]); });
        if (fieldNames.length === 0) {
            throw new Error("field selection should not be empty: ".concat(path.join(".")));
        }
        var type = path.length > 0 ? (0, exports.getFieldFromPath)(ctx.root, path).type : ctx.root;
        var scalarFields = type.scalar;
        var scalarFieldsFragment = void 0;
        if (fieldNames.includes("__scalar")) {
            var falsyFieldNames_1 = new Set(Object.keys(fields_1).filter(function (k) { return !Boolean(fields_1[k]); }));
            if (scalarFields === null || scalarFields === void 0 ? void 0 : scalarFields.length) {
                ctx.fragmentCounter++;
                scalarFieldsFragment = "f".concat(ctx.fragmentCounter);
                ctx.fragments.push("fragment ".concat(scalarFieldsFragment, " on ").concat(type.name, "{").concat(scalarFields
                    .filter(function (f) { return !falsyFieldNames_1.has(f); })
                    .join(","), "}"));
            }
        }
        var fieldsSelection = fieldNames
            .filter(function (f) { return !["__scalar", "__name"].includes(f); })
            .map(function (f) {
            var parsed = parseRequest(fields_1[f], ctx, __spreadArray(__spreadArray([], path, true), [f], false));
            if (f.startsWith("on_")) {
                ctx.fragmentCounter++;
                var implementationFragment = "f".concat(ctx.fragmentCounter);
                var typeMatch = f.match(/^on_(.+)/);
                if (!typeMatch || !typeMatch[1])
                    throw new Error("match failed");
                ctx.fragments.push("fragment ".concat(implementationFragment, " on ").concat(typeMatch[1]).concat(parsed));
                return "...".concat(implementationFragment);
            }
            else {
                return "".concat(f).concat(parsed);
            }
        })
            .concat(scalarFieldsFragment ? ["...".concat(scalarFieldsFragment)] : [])
            .join(",");
        return "{".concat(fieldsSelection, "}");
    }
    else {
        return "";
    }
};
var generateGraphqlOperation = function (operation, root, fields) {
    var ctx = {
        root: root,
        varCounter: 0,
        variables: {},
        fragmentCounter: 0,
        fragments: []
    };
    var result = parseRequest(fields, ctx, []);
    var varNames = Object.keys(ctx.variables);
    var varsString = varNames.length > 0
        ? "(".concat(varNames.map(function (v) {
            var variableType = ctx.variables[v].typing[1];
            return "$".concat(v, ":").concat(variableType);
        }), ")")
        : "";
    var operationName = (fields === null || fields === void 0 ? void 0 : fields.__name) || "";
    return __assign({ query: __spreadArray([
            "".concat(operation, " ").concat(operationName).concat(varsString).concat(result)
        ], ctx.fragments, true).join(","), variables: Object.keys(ctx.variables).reduce(function (r, v) {
            r[v] = ctx.variables[v].value;
            return r;
        }, {}) }, (operationName ? { operationName: operationName.toString() } : {}));
};
exports.generateGraphqlOperation = generateGraphqlOperation;
var getFieldFromPath = function (root, path) {
    var current;
    if (!root)
        throw new Error("root type is not provided");
    if (path.length === 0)
        throw new Error("path is empty");
    path.forEach(function (f) {
        var type = current ? current.type : root;
        if (!type.fields)
            throw new Error("type `".concat(type.name, "` does not have fields"));
        var possibleTypes = Object.keys(type.fields)
            .filter(function (i) { return i.startsWith("on_"); })
            .reduce(function (types, fieldName) {
            var field = type.fields && type.fields[fieldName];
            if (field)
                types.push(field.type);
            return types;
        }, [type]);
        var field = null;
        possibleTypes.forEach(function (type) {
            var found = type.fields && type.fields[f];
            if (found)
                field = found;
        });
        if (!field)
            throw new Error("type `".concat(type.name, "` does not have a field `").concat(f, "`"));
        current = field;
    });
    return current;
};
exports.getFieldFromPath = getFieldFromPath;
