import React from "react";
import TopLogo from "../../assets/img/HALI logo white 1.png";
import TopLogoText from "../../assets/img/HALI.png";
import Deco from "../../assets/img/Deco.png";
import MainLogo from "../../assets/img/Hali logo 1.png";
import Sign from "../../assets/img/23.png";
import { useNavigate } from "react-router-dom";
import { token } from "../../utils/token";
import { RefreshLoginToken } from "../../api";

const HomePage = () => {
  const navigate = useNavigate();

  const handleGetStarted = async () => {
    const tok = await token.get();
    if (tok) {
      await RefreshLoginToken();
      navigate("/jobDetails");
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="hali-page w-full bg-linear bg-[black] min-h-screen">
      <div className="header-div flex flex-row justify-center gap-[950px] pt-7">
        <div className="header-logo flex flex-row ">
          <img className="TopLogo h-[25px] w-[25px]" src={TopLogo} alt="" />
          <img
            className="TopLogoText h-[15px] w-[50px] mt-[5px] ml-[10px]"
            src={TopLogoText}
            alt=""
          />
        </div>
        <div className="flex flex-row gap-6">
          <div
            className="auth-text text-white text-base font-medium cursor-pointer"
            onClick={() => {
              navigate("/login");
            }}
          >
            Login
          </div>
          <button
            className="w-[70px] h-[30px] bg-[#080715] rounded-md border-[1px] border-solid border-[#363737]  text-white text-sm font-medium cursor-pointer"
            onClick={() => {
              navigate("/signup");
            }}
          >
            Sign Up
          </button>
        </div>
      </div>
      <div className="flex flex-column justify-center items-center mt-[10px]">
        <div>
          <div className="main-logo-div ml-[100px]">
            <img
              className="deco-bg-img h-[232px] w-[530px] justify-center items-center "
              src={Deco}
              alt=""
            />
            <div className="">
              <div className="animated-div  h-[96px] w-[96px] ml-[210px] mt-[-170px]">
                <img
                  className="main-logo-img h-[40px] w-[40px] mt-[25px] ml-[25px]"
                  src={MainLogo}
                  alt=""
                />
              </div>
            </div>
          </div>
          <p className="hali-paragraph text-lg font-semibold text-center text-[#FA03FF] mt-4">
            Human Resource Analysis Language Intelligence
          </p>
          <h1 className="hali-slogan text-[40px] font-bold text-center text-[#FDFEFF] mt-[12px]">
            Let’s help you ace your next interview
          </h1>
          <img
            className="signature-mark h-[58px] w-[71px] ml-[600px] mt-[-20px]"
            src={Sign}
            alt=""
          />
          <p className="text-lg font-light text-center text-[#DFDFDF]">
            {" "}
            <span className="text-lg font-black">HALI</span> simulates 100+
            realistic job interviews, analyzes and provides feedback on your{" "}
            <br /> performance, and tracks your progress over time.
          </p>

          <button
            className="get-started-btn w-[100px] h-[45px] bg-[#DF2DB8] hover:bg-[#883ee7] hover:border-[#883ee7] mt-6 ml-[320px] rounded-md border-[1px] border-solid border-[#DF2DB8] text-white text-[14px] font-semibold"
            onClick={handleGetStarted}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
