import React, { useEffect, useRef, useState } from "react";
import TopLogo from "../../assets/img/HALI logo white 1.png";
import TopLogoText from "../../assets/img/HALI.png";
import TickBox from "../../assets/img/Tick Square.png";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastErrorComponents } from "../../App";
import { toast } from "react-toastify";
import { token } from "../../utils/token";
import { GetUser } from "../../api";

const JobDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const prevJobDetails = location.state?.jobDetails;

  const jobIndustries = [
    "Accounting",
    "Aerospace",
    "Agriculture",
    "Automotive",
    "Biotechnology",
    "Construction",
    "Education",
    "Energy",
    "Finance",
    "Healthcare",
    "Information Technology",
    "Manufacturing",
    "Media",
    "Real Estate",
    "Retail",
    "Telecommunications",
    "Transportation",
  ];

  const seniorityLevels = [
    "Intern",
    "Junior",
    "Mid-Level",
    "Senior",
    "Lead",
    "Manager",
    "Director",
    "Vice President",
    "Senior Vice President",
    "Executive",
  ];

  const [jobDetails, setJobDetails] = useState({
    industry: prevJobDetails?.industry || "",
    jobPosition: prevJobDetails?.jobPosition || "",
    seniorityLevel: prevJobDetails?.seniorityLevel || "",
    company: prevJobDetails?.company || "",
    country: prevJobDetails?.country || "",
    jobDescription: prevJobDetails?.jobDescription || "",
  });
  const [industryValue, setIndustryValue] = useState(
    !jobIndustries.includes(jobDetails.industry) ? jobDetails.industry : ""
  );
  const [seniorityValue, setSeniorityValue] = useState(
    !seniorityLevels.includes(jobDetails.seniorityLevel)
      ? jobDetails.seniorityLevel
      : ""
  );
  const [disableIndustryInput, setDisableIndustryInput] = useState(false);
  const [disableSeniorityLevelInput, setDisableSeniorityLevelInput] =
    useState(false);
  const industryInputRef = useRef(null);
  const seniorityInputRef = useRef(null);
  useEffect(() => {
    if (!disableSeniorityLevelInput) {
      seniorityInputRef.current.focus();
    }
  }, [disableSeniorityLevelInput]);
  useEffect(() => {
    if (!disableIndustryInput) {
      industryInputRef.current.focus();
    }
  }, [disableIndustryInput]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenData = urlParams.get("token");

    if (tokenData) {
      token.set(tokenData);
    }
  });

  const handleJobDetailsChange = (event) => {
    const { name, value } = event.target;
    setJobDetails((prevData) => ({ ...prevData, [name]: value }));
    if (name === "industry") {
      if (!jobIndustries.includes(value)) {
        if (value === "Other") {
          setIndustryValue("");
        } else {
          setIndustryValue(value);
        }
        setDisableIndustryInput(false);
      } else {
        setIndustryValue("");
        setDisableIndustryInput(true);
      }
    }
    if (name === "seniorityLevel") {
      if (!seniorityLevels.includes(value)) {
        if (value === "Other") {
          setSeniorityValue("");
        } else {
          setSeniorityValue(value);
        }
        setDisableSeniorityLevelInput(false);
      } else {
        setSeniorityValue("");
        setDisableSeniorityLevelInput(true);
      }
    }
  };

  const handleErrors = () => {
    if (jobDetails.industry === "") {
      toast.error(`Please provide industry`, ToastErrorComponents);
      return false;
    }

    if (jobDetails.jobPosition === "") {
      toast.error(`Please provide job position`, ToastErrorComponents);
      return false;
    }

    if (jobDetails.seniorityLevel === "") {
      toast.error(`Please provide seniority level`, ToastErrorComponents);
      return false;
    }

    return true;
  };

  const handleNextButton = () => {
    if (!handleErrors()) return;
    navigate("/backgroundDetails", { state: { jobDetails } });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenData = urlParams.get("token");

    if (tokenData) {
      token.set(tokenData);
      GetUser();

      urlParams.delete("token");

      // Modify the browser's URL without triggering a page reload
      const newUrl = window.location.pathname + urlParams.toString();
      window.history.replaceState({}, document.title, newUrl);
    }
  });

  return (
    <div className="body-div flex flex-col justify-center items-center w-full min-h-screen bg-[#1D1D1D]">
      <div className="details-head-div flex flex-row justify-center items-center gap-2 pt-4">
        <img
          className="details-top-logo h-[28px] w-[28px]"
          src={TopLogo}
          alt=""
        />
        <img
          className="details-top-logo-text h-[15px] w-[50px]"
          src={TopLogoText}
          alt=""
        />
      </div>
      <p className="hali-interview-text text-[16px] font-medium text-center text-[#D9D9D9] mt-2">
        You are one step closer to acing your next interview. Before we proceed,
        please <br /> provide me with some information on your background,
        seniority level, and areas <br /> of expertise so I can customize this
        experience to best suit your needs.
      </p>
      <div className="spans-div flex flex-row justify-center items-center mt-4">
        <div className="flex flex-col">
          <span className="step-one-span w-[330px] h-[8px]"></span>
          <p className="step-one-text text-sm font-medium text-[#585858] mt-2">
            STEP 1
          </p>
          <div className="job-details-div flex flex-row justify-between mt-2">
            <p className="job-details-text text-base font-medium text-[#fff] opacity-[0.6]">
              Job Details
            </p>
          </div>
        </div>

        <div className="flex flex-col ml-6">
          <span className="step-two-job-span w-[330px] h-[8px]"></span>
          <p className="step-two-text text-sm font-medium text-[#585858] mt-2">
            STEP 2
          </p>
          <p className="background-details-text text-base font-medium text-[#fff] mt-2">
            Upload CV
          </p>
        </div>
      </div>

      <div className="job-details-input mt-4 w-[530px] h-[380px] rounded-2xl bg-[#2F2F2F] border-[1px] border-solid border-[#383838]">
        <p className="provide-details-header text-base font-medium text-[#fff] pt-4 pl-4">
          Provide details on the Job you want to practice for
        </p>
        <hr className="border-[#383838] mt-4" />
        <div className="flex flex-col mt-4 ml-4">
          <label className="text-[#D9D9D9] font-semibold text-xs">
            Industry
          </label>
          <div className="industry-details-div flex flex-row gap-[20px] mt-[5px]">
            <select
              className="industrySelectOption w-[230px] h-[35px] rounded-[8px] bg-[#2B2B2B]"
              name="industry"
              value={jobDetails.industry}
              onChange={handleJobDetailsChange}
            >
              <option className="text-[10px] font-semibold text-[#fff]">
                Other
              </option>
              {jobIndustries.map((industry, index) => (
                <option
                  key={index}
                  className="text-[10px] font-semibold text-[#fff]"
                >
                  {industry}
                </option>
              ))}
            </select>
            <input
              ref={industryInputRef}
              className="industry-input w-[230px] h-[35px] rounded-[8px] bg-[#141718] pl-1 text-white"
              placeholder="Type other here"
              onChange={handleJobDetailsChange}
              name="industry"
              value={industryValue}
              disabled={disableIndustryInput}
            />
          </div>
        </div>

        <div className="flex flex-col mt-4 ml-4">
          <label className="text-[#D9D9D9] font-semibold text-xs">
            Job position
          </label>
          <input
            name="jobPosition"
            placeholder="eg. Product designer"
            className="job-input w-[600px] h-[35px] bg-[#141718] mt-[5px] pl-1 text-white"
            value={jobDetails.jobPosition}
            onChange={handleJobDetailsChange}
          />
        </div>

        <div className="flex flex-col mt-4 ml-4">
          <label className="text-[#D9D9D9] font-semibold text-xs">
            Seniority level
          </label>
          <div className="industry-details-div flex flex-row gap-[20px] mt-[5px]">
            <select
              className="industrySelectOption w-[230px] h-[35px] rounded-[8px] bg-[#2B2B2B]"
              name="seniorityLevel"
              value={jobDetails.seniorityLevel}
              onChange={handleJobDetailsChange}
            >
              <option className="text-[10px] font-semibold text-[#fff]">
                Other
              </option>
              {seniorityLevels.map((seniorityLevel, index) => (
                <option
                  key={index}
                  className="text-[10px] font-semibold text-[#fff]"
                >
                  {seniorityLevel}
                </option>
              ))}
            </select>
            <input
              ref={seniorityInputRef}
              className="industry-input w-[230px] h-[35px] rounded-[8px] bg-[#141718] pl-1 text-white"
              placeholder="Type other here"
              onChange={handleJobDetailsChange}
              name="seniorityLevel"
              value={seniorityValue}
              disabled={disableSeniorityLevelInput}
            />
          </div>
        </div>

        <div className="company-country-label-gap flex flex-row mt-4 ml-4 gap-[190px]">
          <label className="text-[#D9D9D9] font-semibold text-xs">
            Company
          </label>
          <label className="text-[#D9D9D9] font-semibold text-xs">
            Country
          </label>
        </div>

        <div className="industry-details-div flex flex-row gap-[20px] mt-[5px]">
          <input
            className="industry-input w-[230px] h-[35px] bg-[#141718] mt-[5px] pl-1 ml-[15px] text-white"
            placeholder="Type company here"
            name="company"
            value={jobDetails.company}
            onChange={handleJobDetailsChange}
          />

          <input
            className="industry-input w-[230px] h-[35px] bg-[#141718] mt-[5px] pl-1 text-white"
            placeholder="Type country here"
            name="country"
            value={jobDetails.country}
            onChange={handleJobDetailsChange}
          />
        </div>

        <div className="flex flex-col mt-4 ml-4">
          <label className="text-[#D9D9D9] font-semibold text-xs">
            Job Description
          </label>
          <textarea
            className="job-input w-[500px] h-[174px] bg-[#141718] mt-[5px] pl-1 text-white"
            placeholder="Type Job Description Here"
            name="jobDescription"
            value={jobDetails.jobDescription}
            onChange={handleJobDetailsChange}
          />
        </div>

        <div className="next-btn-div flex flex-row justify-end gap-4 mt-[100px] mr-[50px] mb-[10px]">
          <button
            className="back-btn bg-[#585858] w-[110px] h-[45px] rounded-md border-[1px] border-solid border-[#585858] text-[#FFF] text-base font-semibold"
            onClick={handleNextButton}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
