"use strict";
exports.__esModule = true;
exports["default"] = {
    scalars: [1, 2, 5, 7, 10, 11, 12, 16],
    types: {
        Query: {
            getAiCompletionText: [
                1,
                {
                    prompt: [1, "String!"]
                },
            ],
            getTextFromAudio: [
                1,
                {
                    file: [2, "Upload!"]
                },
            ],
            getAudioFromText: [
                3,
                {
                    input: [4, "GetAudioFromTextInput!"]
                },
            ],
            getUserInterviews: [
                6,
                {
                    pagination: [15, "PaginationInput!"]
                },
            ],
            getUserLatestInterviews: [6],
            getJobDetailInterview: [
                6,
                {
                    pagination: [15, "PaginationInput!"],
                    jobDetailsId: [1, "String!"]
                },
            ],
            getUserJobDetails: [
                8,
                {
                    userJobDetailsId: [1, "String!"]
                },
            ],
            getLatestUserJobDetails: [8],
            getAllUserJobDetails: [8],
            getLoggedInUser: [9],
            __typename: [1]
        },
        String: {},
        Upload: {},
        AIResponseType: {
            audioBlob: [1],
            __typename: [1]
        },
        GetAudioFromTextInput: {
            prompt: [1],
            voice: [5],
            __typename: [1]
        },
        OpenAISpeechVoices: {},
        Interview: {
            _id: [1],
            created_at: [7],
            updated_at: [7],
            userJobDetails: [8],
            aiQuestion: [1],
            userResponse: [1],
            performance: [12],
            __typename: [1]
        },
        DateTime: {},
        UserJobDetails: {
            _id: [1],
            created_at: [7],
            updated_at: [7],
            user: [9],
            industry: [1],
            jobPosition: [1],
            seniorityLevel: [1],
            background: [1],
            areaOfExpertiseOrSkills: [1],
            company: [1],
            country: [1],
            jobDescription: [1],
            lastAiQuestion: [1],
            questionNumber: [12],
            cvDataExtraction: [1],
            interviewPerformance: [13],
            __typename: [1]
        },
        User: {
            _id: [1],
            created_at: [7],
            updated_at: [7],
            email: [1],
            name: [1],
            role: [10],
            termsAndConditionsAccepted: [7],
            avatar: [1],
            emailVerified: [11],
            cvName: [1],
            cvDataExtraction: [1],
            __typename: [1]
        },
        UserRole: {},
        Boolean: {},
        Float: {},
        InterviewPerformance: {
            overallRatingInPercentage: [12],
            summary: [1],
            categoryInfo: [14],
            __typename: [1]
        },
        CategoryInfo: {
            name: [1],
            rating: [12],
            aiAnalysis: [1],
            suggestionsOfImprovement: [1],
            __typename: [1]
        },
        PaginationInput: {
            pageSize: [16],
            pageNumber: [16],
            __typename: [1]
        },
        Int: {},
        Mutation: {
            createInterview: [
                6,
                {
                    input: [18, "CreateInterviewInput!"]
                },
            ],
            createUserJobDetails: [
                8,
                {
                    file: [2],
                    input: [19, "UserJobDetailsCreateInput!"]
                },
            ],
            updateUserJobDetails: [
                8,
                {
                    input: [20, "UserJobDetailsUpdateInput!"]
                },
            ],
            logIn: [
                21,
                {
                    input: [22, "UserLogInInput!"]
                },
            ],
            refreshLogInToken: [1],
            updateUserProfile: [
                9,
                {
                    input: [23, "UserProfileInput!"]
                },
            ],
            acceptTermsAndConditions: [11],
            userSignup: [
                9,
                {
                    input: [24, "UserSignupInput!"]
                },
            ],
            __typename: [1]
        },
        CreateInterviewInput: {
            userJobDetailsId: [1],
            aiQuestion: [1],
            userResponse: [1],
            __typename: [1]
        },
        UserJobDetailsCreateInput: {
            industry: [1],
            jobPosition: [1],
            seniorityLevel: [1],
            background: [1],
            areaOfExpertiseOrSkills: [1],
            company: [1],
            country: [1],
            jobDescription: [1],
            __typename: [1]
        },
        UserJobDetailsUpdateInput: {
            userJobDetailsId: [1],
            lastAiQuestion: [1],
            questionNumber: [12],
            __typename: [1]
        },
        LoggedInUser: {
            user: [9],
            sessionToken: [1],
            __typename: [1]
        },
        UserLogInInput: {
            email: [1],
            password: [1],
            __typename: [1]
        },
        UserProfileInput: {
            name: [1],
            currentPassword: [1],
            newPassword: [1],
            __typename: [1]
        },
        UserSignupInput: {
            name: [1],
            email: [1],
            password: [1],
            __typename: [1]
        }
    }
};
