"use strict";
// @ts-nocheck
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
exports.__esModule = true;
exports.createClient = void 0;
var fetcher_1 = require("./fetcher");
var generateGraphqlOperation_1 = require("./generateGraphqlOperation");
var createClient = function (_a) {
    var queryRoot = _a.queryRoot, mutationRoot = _a.mutationRoot, subscriptionRoot = _a.subscriptionRoot, options = __rest(_a, ["queryRoot", "mutationRoot", "subscriptionRoot"]);
    var fetcher = (0, fetcher_1.createFetcher)(options);
    var client = {};
    if (queryRoot) {
        client.query = function (request) {
            if (!queryRoot)
                throw new Error("queryRoot argument is missing");
            var resultPromise = fetcher((0, generateGraphqlOperation_1.generateGraphqlOperation)("query", queryRoot, request));
            return resultPromise;
        };
    }
    if (mutationRoot) {
        client.mutation = function (request) {
            if (!mutationRoot)
                throw new Error("mutationRoot argument is missing");
            var resultPromise = fetcher((0, generateGraphqlOperation_1.generateGraphqlOperation)("mutation", mutationRoot, request));
            return resultPromise;
        };
    }
    return client;
};
exports.createClient = createClient;
